// Styling for the navbar
#mainNav {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: solid 4px $orange;
  outline: 4px solid $olive;
  @include heading-font;
  .navbar-brand {
    color: $white;
    img {
        height: 26px;
        padding: 0px !important;
        margin: 0 2px 4px 2px !important;
        display: inline-block !important;
    }
    span {
        display: block;
        margin-top: -0.2em;
        margin-bottom: -0.2em;
    }
    span.tagline {
        font-size: 45%;
        text-transform: none;
        letter-spacing: 1px;
    }
  }
  .navbar-nav {
    margin-top: 1rem;
    letter-spacing: 0.0625rem;
    li.nav-item {
      a.nav-link {
        color: $white;
        &:hover {
          color: $olive;
        }
        &:active,
        &:focus {
          color: $white;
        }
        &.active {
          color: $primary;
        }
      }
    }
  }
  .navbar-toggler {
    font-size: 80%;
    padding: 0.8rem;
    background-color: $gray-900;
  }
}
@media(min-width:992px) {
  #mainNav {
    -webkit-transition: padding-top 0.3s, padding-bottom 0.3s;
    -moz-transition: padding-top 0.3s, padding-bottom 0.3s;
    transition: padding-top 0.3s, padding-bottom 0.3s;
    .navbar-brand {
      font-size: 2em;
      -webkit-transition: font-size 0.3s;
      -moz-transition: font-size 0.3s;
      transition: font-size 0.3s;
      padding-top: 0;
      padding-bottom: 0;
    }
    .navbar-nav {
      margin-top: 0;
    }
    .navbar-nav > li.nav-item > a.nav-link.active {
      color: $white;
      background: $secondary;
      &:active,
      &:focus,
      &:hover {
        color: $white;
        background: $gray-400;
      }
    }
  }
  #mainNav.navbar-shrink {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    .navbar-brand {
      font-size: 1.5em;
    }
  }
}
