// Bootstrap overrides for this template
a {
  color: $primary;
  &:focus,
  &:hover,
  &:active {
    color: darken($primary, 10%);
  }
}

.btn {
  border-width: 2px;
}

.bg-primary_dopsys {
  background-color: $primary;
}

.bg-secondary {
  background-color: $secondary !important;
}

.text-primary {
  color: $primary !important;
}

.text-secondary {
  color: $secondary !important;
}

.btn-primary {
  background-color: $primary;
  border-color: $primary;
  &:hover,
  &:focus,
  &:active {
    background-color: darken($primary, 10%);
    border-color: darken($primary, 10%);
  }
}

.btn-secondary {
  background-color: $secondary;
  border-color: $secondary;
  &:hover,
  &:focus,
  &:active {
    background-color: darken($secondary, 10%);
    border-color: darken($secondary, 10%);
  }
}