header.masthead {
  padding-top: calc(6rem + 72px);
  padding-bottom: 6rem;
  background-image: url('../img/masthead.jpg');
  background-position: center;
  position: relative;
  h1 {
    font-size: 3rem;
    line-height: 3rem;
  }
  h2 {
    font-size: 1.3rem;
    @include body-font;
  }
  .container img{
      width: 40%;
      border-radius: 50%;
      margin-top: -5%;
      position: relative;
      z-index: 2;
      -webkit-filter: drop-shadow( 0 0 10px rgba(0,0,0,0.9) ); 
      filter: drop-shadow( 0 0 10px rgba(0,0,0,0.9) );
  }
  .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(22, 31, 37, 0.74);
      z-index: 1;
      top: 0px;
  }
}
@media (min-width: 992px) {
  header.masthead {
    padding-top: calc(6rem + 106px);
    padding-bottom: 6rem;
    h1 {
      font-size: 4.75em;
      line-height: 4rem;
    }
    h2 {
      font-size: 1.75em;
    }

  }
}
