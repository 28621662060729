// Variables

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
$primary: #ececee !default;
$secondary: #677e87 !default;
$terciary: #51636a;
$orange: #f99a3e;
$olive: #00ae97;
$green: #009078;