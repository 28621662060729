// Styling for the partners section
.portfolio {
    .align-middle img,
    .align-middle a {
        line-height: 100%;
        display: inline-block;
        max-height: 160px;
        text-align: center;
        height: 100%;
        width: 100%;
    }
    .align-middle a img {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);        
    }
    .align-middle a:hover img {
        -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
        filter: grayscale(0%);        
    }
}

